<template>
  <div>
    <Header />
    <RatingComponent :subunitId="subunitId" :activeTab="activeTab" @tab-change="handleTabChange" />
    <Footer />
  </div>
</template>

<script>
import RatingComponent from '@/components/Rating/index.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { mapActions } from 'vuex'
export default {
  name: 'Statistic',
  components: {
    RatingComponent,
    Header,
    Footer
  },
  data() {
    return {
      subunitId: null,
      activeTab: 'all'
    };
  },
  async created() {
    this.setActiveTab('all');
    // this.subunitId = this.$store.getters['auth/user'].club.references[1] || null;
    await this.$store.dispatch('statistic/getTrainingTypes')
    // await this.$store.dispatch('rating/getClubRating', { loading: true, subunitId: this.subunitId })
  },
  methods: {
    ...mapActions('rating', ['changeParams', 'getClubRating', 'clear']),
    handleTabChange(tab) {
      this.activeTab = tab;
      this.setActiveTab(tab); 
    },
    async setActiveTab(tab) {
      this.clear()
      this.activeTab = tab;

      if (tab === 'all') {
        this.subunitId = null;
      } else if (tab === 'group') {
        this.subunitId = this.$store.getters['auth/user'].club.references[0] || null;
      } else if (tab === 'subunit') {
        this.subunitId = this.$store.getters['auth/user'].club.references[1] || null;
      }

      // const paramsDateFormat = this.$moment().format("YYYY-MM")
      // this.changeParams({
      //   value: paramsDateFormat,
      //   changeFor: 'period',
      // });

      await this.$store.dispatch('rating/getClubRating', {
        subunitId: this.subunitId,
        loading: true
      });
    },
  },
  destroyed() {
    this.$store.dispatch('rating/clear')
  },
}
</script>
